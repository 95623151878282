import React, { useState, useEffect, useContext } from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';
import FunnelContext from '../../context/FunnelContext';

const LocalCartText = props => {
  const { displayValue } = props;
  const { extraObjects, funnelUpdated } = useContext(FunnelContext);

  const [value, setValue] = useState();
  const [localCart] = useLocalStorage('local-cart');
  const [extraObjects2] = useLocalStorage('extraObjects');

  useEffect(() => {
    const symbol = localCart?.currency?.symbol;

    switch (displayValue) {
      case 'main-product-name':
        setValue(localCart?.product?.checkoutData?.title);
        break;
      case 'main-product-price':
        const rrp = localCart?.product?.checkoutData?.perceived_rrp.toFixed(2);
        setValue(`${symbol}${rrp}`);
        break;
      case 'main-product-discounted-price':
        const discounted_price = localCart?.product?.checkoutData?.discounted_price.toFixed(
          2
        );
        setValue(`${symbol}${discounted_price}`);
        break;
      case 'main-product-display-title':
        setValue(localCart?.product?.display_title);
        break;
      case 'main-product-category':
        setValue(
          localCart?.extraData?.currentCategory === 'subscriptions'
            ? 'Subscription'
            : 'One Time'
        );
        break;
      case 'main-product-frequency':
        setValue(
          localCart?.extraData?.currentCategory === 'subscriptions'
            ? localCart?.extraData?.schedule_text
            : ''
        );
        break;
      case 'free-gift-name':
        setValue(extraObjects2?.free_gift_title);
        break;
      case 'free-gift-value':
        const free_gift_value = localCart?.product?.free_gift_value;
        setValue(`${symbol}${free_gift_value}`);
        break;
      case 'total-amount':
        setTimeout(() => {
          const total_amount = extraObjects?.localCart?.totalAmount?.toFixed(2);
          setValue(`${symbol}${total_amount}` || `${symbol}0.00`);
        });
        break;
      case 'total-savings':
        setTimeout(() => {
          const total_savings = extraObjects?.localCart?.totalSavings?.toFixed(
            2
          );
          setValue(`${symbol}${total_savings}` || `${symbol}0.00`);
        });
        break;
      default:
        setValue('[no-value]');
        break;
    }
  }, [
    displayValue,
    setValue,
    localCart,
    extraObjects2,
    extraObjects,
    funnelUpdated
  ]);

  const Value = () => <>{value}</>;

  return <Value />;
};

export default LocalCartText;
